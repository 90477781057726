export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? Container

    '.container': {
      padding: '0.5rem 1rem',
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',

      '.smallNavMenu': {
        '.react-reveal': {
          opacity: '1'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'transparent',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      '.hamburger': {
        height: '45px',
        width: '45px',
        backgroundColor: 'white',
        '>div': {
          height: '2px'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      }
    },

    // ? Logo

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem',
      padding: '0rem'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '120px', ''],
        // filter: 'brightness(0) invert(1)',
        padding: '0rem',
        filter: 'unset'
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        padding: '0rem',
        maxHeight: ['40px', '60px', '60px', '60px']
      }
    },

    // ? Small Nav Menu

    '.smallNavMenu': {
      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '.navItem': {
        a: {
          color: 'primary'
        }
      },
      'li.contact': {
        variant: 'buttons.navButton',
        a: { color: 'white' }
      }
    },

    // ? hamburger

    '.hamburger': {
      // borderRadius: '100px',
      padding: '0.75rem',
      margin: '0rem 0rem 0rem 1rem',
      borderColor: 'primary',
      // backgroundColor: 'secondary',
      '> div': {
        // backgroundColor: 'primary',
      }
    },
    '.hamburgerOpen': {
      // borderRadius: '100px',
      margin: ['', '', '', '0rem 0rem 0rem 1rem'],
      padding: '0.75rem',
      borderColor: 'primary',
      '> div': {
        // backgroundColor: 'primary',
        width: '75%'
      }
    },

    // ? Nav Menu

    '.navMenuLogo': {
      // display: 'none',
    },

    '.navMenu': {
      transform: 'unset',
      right: '0rem',
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'background'
    },

    '.navMenuOpen': {
      alignItems: ['flex-end'],
      padding: ['3rem 1rem 2rem', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'background',

      '.navItemDropdown': {
        width: '100%',
        textAlign: 'right',
        '.navItemDropdownMenu': {
          background: 'none',
          borderTop: 'solid 1px grey',
          borderBottom: 'solid 1px grey',
          position: 'static',
          '.navItem': {
            a: {
              fontSize: ['0.85rem', '1rem', '1.1rem', '1.2rem', '1.2rem']
            }
          }
        }
      },

      '.navItem': {
        textAlign: 'right',
        justifyContent: 'flex-end',
        whiteSpace: 'normal',
        textShadow: 'none',
        a: {
          fontSize: ['1rem', '1.2rem', '1.3rem', '1.4rem', '1.5rem'],
          color: 'primary',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0rem 1rem auto'],
        width: '100%',
        maxWidth: 'unset',
        backgroundColor: 'primary',
        borderColor: 'primary',
        borderWidth: '0.5px',
        height: '0.5px',
        order: '11'
      },
      '.phoneSocialContainer': {
        order: '12',

        flexDirection: ['column', '', '', 'column'],
        alignItems: ['flex-end'],
        textAlign: ['right'],
        width: ['100%', '100%', '100%', '100%'],
        '.phoneContainer': {
          width: '100%'
        },
        a: {
          textAlign: ['right'],
          justifyContent: ['flex-end'],
          fontSize: '1rem',
          margin: '0rem 0rem 0.25rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['right'],
        color: 'secondary'
      },

      '.socialIconsContainer': {
        alignItems: ['flex-end'],
        textAlign: ['right'],
        width: ['100%', '100%', '100%', '100%'],
        justifyContent: 'flex-end',
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['right'],
          justifyContent: ['flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'primary'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh',
      opacity: '0'
    },
    '.navBlockOpen': {
      // backgroundColor: 'transparent',
    },

    // ?  widgets

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        color: 'primary',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    }
  },

  footer: {
    backgroundColor: 'secondary',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        color: 'text'
      },
      '.contact-us': { order: '20' }
    },
    '.contactDetails-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'text',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    '.copyright': {
      width: '50%',
      color: 'text',

      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px black',
          borderRadius: '100px',
          color: 'text',
          fill: 'text',
          path: {
            fill: 'text'
          },
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%',
      filter: 'unset'
      // '.poweredByText': {
      //   color: 'text',
      //   svg: {
      //     path: {
      //       fill: 'text',
      //     },
      //   },
      // },
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    textAlign: 'inherit',
    alignItems: 'inherit',
    marginBottom: '1.5rem',
    border: 'none',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.5rem', '2.75rem'],
    fontWeight: 'normal',
    fontFamily: 'heading',
    color: '#433a3a',
    order: '1'
  },
  subtitle: {
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.5rem', '2.75rem'],
    fontWeight: '300',
    alignItems: 'inherit',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'body',
    color: 'primary',
    border: 'none',
    order: '2'
  },
  text: {
    textAlign: 'inherit',
    marginBottom: '0.5rem',
    color: 'gray',
    order: '3',

    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'inherit',
      color: 'gray',
      fontWeight: '300'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  // },

  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1.5rem', '2rem', '3rem', '5rem 5rem 3rem'],
    maxWidth: '1200px',
    textAlign: 'left',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '5rem'],
      margin: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    marginTop: '150px',
    height: 'unset',
    maxHeight: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.hero_content_container': {
      position: 'static',
      order: '1',
      // top: '8%',
      // left: '50%',
      transform: 'unset',
      color: 'unset',
      padding: ['1rem', '', '2rem'],
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      maxWidth: '1000px',
      margin: ' 0rem auto',
      textAlign: 'center',
      marginBottom: '3rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '4.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '2rem'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '', '1.4rem'],
      p: {
        color: 'grey',
        fontSize: ['1.25rem', '', '', '1.5rem'],
        lineHeight: ['1.5', '1.75', '', '1.75'],
        fontWeight: '300'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-initialized': {
      order: '2',

      width: '100%',
      height: 'auto',
      '.slick-slide': {
        height: '85vh',
        '> div': {
          height: '85vh',
          img: {
            height: '85vh',
            filter: 'brightness(0.9)'
          }
        }
      },

      '.slick-arrow': {
        left: 'unset',
        right: '1rem',
        top: '2rem',
        bottom: 'unset',
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        width: '40px',
        height: '40px'
      },
      '.slick-prev': {
        right: '4rem'
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageVenues: {},

  homepageContact: {
    background: 'unset',
    paddingBottom: '1rem',
    '.title': {
      variant: 'customVariants.title',
      order: '1',
      color: 'lightgrey',
      fontSize: ['2rem', '2.5rem', '3rem', '6rem', '7rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
      color: 'primary'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageBoxes1: {
    // backgroundColor: '#efefef',
    padding: ['0rem', '0rem', '0rem', '0rem'],

    '.contentBoxes > div:nth-of-type(odd)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      textAlign: 'right',
      'div.text': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }
    },
    '.contentBoxes > div:nth-of-type(even)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      textAlign: 'left'
    },

    '.box': {
      // height: '70vh',
      width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
      // margin: ['2rem 0rem 8rem', '', '0rem 0rem 10rem'],
      border: 'solid 1px',
      borderColor: 'background',
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      flexGrow: '1',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      backgroundColor: 'secondary',

      padding: ['1rem', '2rem', '3rem', '4rem'],

      // ':hover': {
      //   border: 'solid 1px #efefef',
      //   '.image': {
      //     filter: 'grayscale(100%)',
      //   },
      //   backgroundColor: 'white',
      // },
      '.image': {
        height: ['auto', '', '450px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 1s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '0',
        objectFit: 'cover',
        display: 'none'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
        transition: 'all ease-in-out 1s',
        padding: '1rem',
        zIndex: '1',
        margin: '0rem'
        // width: ['90%', '70%', '70%', '85%', '70%'],
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '1rem',
        zIndex: '1',
        margin: '0rem',
        color: '#b8933d',
        // width: ['90%', '70%', '70%', '85%', '70%'],
        fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.75rem', '2rem']
      },
      '.text': {
        variant: 'customVariants.text',
        zIndex: '1',
        padding: '1rem',
        margin: '0rem',

        // width: ['90%', '70%', '70%', '85%', '70%'],
        p: {
          maxWidth: '350px'
        }
      },
      a: {
        padding: '1rem',
        margin: '0rem'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        order: '4',
        zIndex: '1',
        // margin: '0rem 0rem -3rem',
        ':hover': {
          backgroundColor: '#202020'
        }
      }
    }
  },

  // homepageBoxes1: {
  //   // backgroundColor: '#efefef',
  //   '.box': {
  //     width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
  //     margin: '1.5rem',
  //     border: 'solid 1px',
  //     borderColor: 'background',
  //     transition: 'all ease-in-out 1s',
  //     borderRadius: '0px',
  //     flexGrow: '1',
  //     ':hover': {
  //       border: 'solid 1px #efefef',
  //       '.image': {
  //         filter: 'grayscale(100%)',
  //       },
  //       backgroundColor: 'white',
  //     },
  //     '.image': {
  //       height: ['auto', '', '450px'],
  //       objectFit: 'cover',
  //       transition: 'all ease-in-out 1s',
  //     },
  //     '.title': {
  //       variant: 'customVariants.title',
  //       fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
  //       transition: 'all ease-in-out 1s',
  //       padding: '0.5rem 1rem 0rem',
  //     },
  //     '.subtitle': {
  //       variant: 'customVariants.subtitle',
  //       padding: '0rem 1rem',
  //     },
  //     '.text': {
  //       variant: 'customVariants.text',
  //       padding: '0rem 1rem',
  //     },
  //     '.ctaLink': {
  //       variant: 'buttons.primary',
  //       order: '4',
  //       margin: 'auto auto 1rem 1rem',
  //       ':hover': {
  //         backgroundColor: 'primary',
  //       },
  //     },
  //   },
  // },

  homepageProperty: {
    padding: '20vh 1rem',
    '.section': {
      padding: ['2rem', '', '3rem'],
      backgroundColor: 'rgba(0,0,0,0.5)',

      '.title': {
        variant: 'customVariants.title',
        // fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
        transition: 'all ease-in-out 1s',
        padding: '0rem 1rem 0rem',
        fontSize: ['2rem', '2.5rem', '3.5rem', '3.5rem', '4rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '0rem 1rem',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2rem'],
        margin: '0rem',
        color: 'white',
        textAlign: 'left',
        textTransform: 'unset',
        opacity: '0.7',
        marginLeft: '-25%'
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '0rem 1rem',
        P: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary',
        color: 'white',
        borderColor: 'white'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '0rem 0rem 1rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.imageContainer': {
      '.image1': {
        height: '60%',
        margin: 'auto',
        position: 'relative',
        right: ['-2rem', '', '-3rem'],
        backgroundColor: '#f9f9f9',
        border: 'solid 9px',
        borderColor: 'primary'
      },
      '.image2': {
        border: 'solid 9px',
        borderColor: 'secondary',
        marginBottom: '0.5rem'
      },
      '.image3': {
        border: 'solid 9px',
        borderColor: 'grey'
      }
    }
  },

  homepageVenues: {
    variant: 'customVariants.propertyContentBlock1'
  },

  homepageBoxes2: {
    padding: '0rem',

    '.box': {
      borderRadius: '0px',
      width: ['100%', '', '50%'],
      margin: '0rem',
      height: '75vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3rem',
      color: 'white',
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      '::before': {
        content: "''",
        width: '90%',
        position: 'absolute',
        height: '70%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '-1',
        border: 'solid 2px',
        borderColor: 'primary'
      },
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        margin: '0rem'
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'white',
        p: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      },
      '.image': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        zIndex: '-2',
        filter: 'brightness(0.8)'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        order: '4',
        margin: '0rem auto',
        borderColor: 'white',
        color: 'white',
        ':hover': {
          backgroundColor: 'primary'
        }
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '', '0rem 0rem'],
    borderTop: '1px solid',
    borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'primary',
      fontFamily: 'subheading',
      width: ['', '', '80%'],
      color: 'white'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['transparent'],
      color: 'text'
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'text',
      flexGrow: '1'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  homepageTestimonials: {
    maxWidth: '700px',
    margin: ' 0rem auto 4rem',
    backgroundColor: 'secondary',
    backgroundColor: 'tertiary',
    borderRadius: '20px',
    width: '90%',
    '.menuSectionTitle': {
      fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '4.5rem'],
      borderTop: 'dotted 4px',
      borderBottom: 'dotted 4px',
      borderColor: 'primary',
      color: 'primary'
    },
    '.menuSectionDescription': {
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5'
    },

    '.menuItemName': {
      color: 'secondary'
    },

    '.menuItemNameContainer': {
      textAlign: 'center'
    },
    '.menuItemDescription': {
      textAlign: 'center',
      width: '100%',
      padding: '1rem',
      lineHeight: '1.6',
      maxHeight: '200px',
      overflowY: 'scroll'
    },

    '.slick-slider': {
      '.slick-arrow': {
        height: '35px',
        width: '35px',
        color: 'primary',
        right: 'unset',
        left: 'unset',
        top: 'unset',
        bottom: '-3rem',
        background: 'rgba(255,255,255,0.5)'
      },

      '.slick-prev': {
        left: '1rem'
      },

      '.slick-next': {
        left: '4.5rem'
      },

      '.slick-dots': {
        li: {
          button: {
            '::before': {
              color: '#327d8adb',
              fontSize: '1.5rem'
            }
          }
        }
      },
      '.slick-dots li.slick-active button:before': {
        color: 'primary'
      }
    }
  },

  homepageQuote1: {
    padding: '20vh 1rem',
    '.section': {
      maxWidth: '95%'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2.5rem', '3.5rem', '3.5rem', '4rem'],
      order: '2'
    },
    '.subtitle': {
      order: '1',
      variant: 'customVariants.subtitle',
      color: 'lightgrey',
      borderBottom: '4px solid'
    },
    a: {
      order: '6',
      '.ctaButton': {}
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    padding: '5rem 2rem 0rem',

    '.lazyload-wrapper': {
      overflow: 'hidden',
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  about1: {
    variant: 'customVariants.propertyContentBlock1',
    '.title': {
      color: 'primary',
      fontSize: ['2rem', '2.5rem', '3rem'],
      '::first-letter': {
        fontSize: '300%',
        fontWeight: 'normal'
      }
    }
  },

  about2: {
    variant: 'customVariants.propertyContentBlock1',
    '.title': {
      color: 'primary',
      fontSize: ['2rem', '2.5rem', '3rem'],
      '::first-letter': {
        fontSize: '300%',
        fontWeight: 'normal'
      }
    }
  },

  about3: {
    variant: 'customVariants.propertyContentBlock1',
    '.title': {
      color: 'primary',
      fontSize: ['2rem', '2.5rem', '3rem'],
      '::first-letter': {
        fontSize: '300%',
        fontWeight: 'normal'
      }
    }
  },

  // ? ========================
  // ? ====  Wedding page  ====
  // ? ========================

  // menu: {},

  weddingAbout: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '0rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    backgroundColor: 'transparent',
    background: 'none'
  },

  weddingHero: {
    height: 'auto',
    '.hero_content_container': {
      position: 'static',
      color: 'unset',
      transform: 'unset',
      padding: ['3rem 1rem', '', '4.5rem 3rem 2rem'],
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '767px',
      margin: ' 0rem auto',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '5rem', '6rem'],
      fontWeight: 'bold'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '', '1.4rem'],
      p: {
        fontSize: ['1.25rem', '', '', '1.5rem'],
        lineHeight: ['1.5', '1.75', '', '1.75'],
        fontWeight: '300'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-initialized': {
      '.slick-slide': {
        height: '60vh',
        '> div': {
          height: '60vh',
          img: {
            height: '60vh'
          }
        }
      },

      '.slick-arrow': {
        left: 'unset',
        right: '1rem',
        top: '2rem',
        bottom: 'unset',
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        width: '40px',
        height: '40px'
      },
      '.slick-prev': {
        right: '4rem'
      }
    },
    display: 'flex',
    flexDirection: 'column',

    '.slick-slider': {
      order: '1',
      '.slick-arrow': {
        top: 'unset',
        bottom: '1rem'
      }
    },

    'div.hero_content_container': {
      maxWidth: '1200px',
      order: '2',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['2rem', '3rem', '4rem', '5rem', '5rem'],
        fontWeight: 'bold'
      },

      '.text': {
        p: {
          lineHeight: '1.5',
          fontSize: ['1rem', '1.2rem']
        }
      }
    }
  },

  weddingGallery: {
    '.albumsContainer, .albumName': {
      display: 'none'
    }
  },

  WeddingContentBlock: {
    variant: 'customVariants.sideBySide1',
    'div.content': {
      backgroundColor: 'white',
      padding: ['1rem', '2rem', '3rem', '4rem'],
      '.text': {
        p: {
          fontSize: ['1rem', '1.1rem', '1.25rem', '1.3rem']
        },
        marginBottom: '1.5rem'
      }
    },

    maxWidth: '1000px',
    margin: '0rem auto'
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // events: {
  //   '.events-container': {
  //     maxWidth: 'unset',
  //   },
  //   '.no-events-container': {
  //     padding: '10rem 1rem',
  //     img: {
  //       maxHeight: '70px',
  //       // filter: 'brightness(0) invert(1)',
  //     },
  //   },

  //   '.eventItemImage': {
  //     minHeight: 'unset',
  //     width: '25%',
  //     // height: '100%',
  //   },
  //   '.eventDays, .eventDate': {
  //     color: 'primary',
  //   },
  //   '.eventItemDescription': {
  //     maxHeight: '250px',
  //     overflowY: 'scroll',
  //     '::-webkit-scrollbar': {
  //       display: 'none',
  //     },
  //     '-ms-overflow-style': 'none' /* IE and Edge */,
  //     scrollbarWidth: 'none',
  //   },
  // },

  eventsContentIntro: {
    background: 'transparent',
    '.section': {
      maxWidth: '1000px'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3rem', '4rem'],
      fontWeight: 'bold'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '', '1.5rem'],
      p: {
        fontSize: ['1.25rem', '', '', '1.5rem'],
        lineHeight: ['1.5', '1.75', '', '2'],
        fontWeight: '300'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  eventGallery: {
    '.albumsContainer, .albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    backgroundColor: 'white',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    h3: {
      color: 'secondary'
    },
    'p.text': {
      color: 'text',
      maxWidth: '650px',
      margin: '2rem 0rem'
    },
    backgroundPosition: 'center center',
    color: 'dark',
    padding: ['3rem 1rem', '', '3rem 1rem'],
    '.inputField, .textField ': {
      border: 'none',
      borderBottom: '2px solid',
      fontWeight: '300',
      borderColor: 'primary',
      fontFamily: 'body',

      borderWidth: '1px',
      borderRadius: '0px',
      padding: '0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text',
        fontFamily: 'body'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto',
      padding: '0.5rem 1rem'
    },

    // .datefield {
    //   width: 100%;
    // }

    // .datefield.placeholderclass::before {
    //   width: 100%;
    //   content: attr(placeholder);
    // }

    // ".datefield.placeholderclass:hover::before": {
    //   width: 0%;
    //   content: "";
    // }

    // '.datefield:focus:before, .datefield:valid:before': {
    //   content: "''",
    // },
    '.datefield:before': {
      content: "'Event Date -'",
      color: '#857570',
      fontFamily: 'body',
      marginRight: '1em'
    }
  },

  locationMap: {
    order: '3'
    // '.content_container': {
    //   padding: '0rem',
    // },
    // h3: {
    //   variant: 'customVariants.title',
    //   justifyContent: 'center',
    //   display: 'flex',
    //   order: 'unset',
    //   width: '100%',
    //   backgroundColor: 'secondary',
    //   padding: '0.5rem 0.5rem 1rem',
    //   textAlign: 'center',
    //   fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
    //   textAlign: 'center',
    //   alignItems: 'center',
    // },
    // '.contactDetails-container': {
    //   padding: '0rem 1rem 3rem',
    // },
    // '.hours-section': {
    //   maxWidth: 'unset',
    //   padding: '0rem',
    //   '.hoursContainer': {
    //     padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
    //   },
    // },
    // '.dayofWeekText': {
    //   margin: '0 auto 1rem 0',
    //   borderBottom: '1px solid',
    // },
    // '.textContent': {
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   textAlign: 'center',
    //   display: 'flex',
    // },
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  team   ========
  // ? ========================
  teamMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },

    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  teamPage: {
    '::before': {
      backgroundColor: 'white',
      // height: '150px',
      marginBottom: '2rem'
    },
    '.contentRow': {
      padding: '1rem'
    },
    '.imageContainer': {
      width: ['100%', '100%', '40%', '45%']
    },
    '.contentRow': {
      width: ['100%', '100%', '60%', '55%'],
      padding: ['2rem', '2rem', '3rem', '4rem'],
      '.title': {
        fontSize: ['3rem', '4rem', '5rem']
      },
      '.subtitle': {
        color: 'primary',
        fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
        margin: '2rem 0rem'
      }
    }
  },

  // ? ===============================
  // ? =======  Our Property   =======
  // ? ===============================

  propertyContentIntro: {
    variant: 'customVariants.sideBySide1',
    padding: ['2rem', '3rem', '4rem', '5rem'],
    maxWidth: '1300px',
    margin: '0rem auto',
    overflow: 'hidden',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '70vw', '70vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        right: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },
  propertyContentBlock1: {
    variant: 'customVariants.propertyContentIntro',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '60vw', '60vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  propertyContentBlock2: {
    variant: 'customVariants.propertyContentIntro',
    '.imageContainer': {
      width: '100%',
      'div.lazyload-wrapper': {
        width: '100%',
        margin: ['0rem', '0rem', '0rem', '1rem 0rem 1rem 1rem'],
        img: {
          position: 'static',
          transform: 'unset'
        },
        '::after': {
          content: '',
          display: 'none'
        }
      }
    }
  },

  propertyContentBlock3: {
    variant: 'customVariants.propertyContentIntro',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '70vw', '70vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  // ? ===================================
  // ? =====  Intimate Ceremonies   ======
  // ? ===================================

  pricing: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', '', 'column', 'row'],
    'div.content': {
      backgroundColor: 'white',
      padding: ['1rem', '2rem', '3rem', '4rem'],
      width: ['100%', '', '', '65%'],
      '.text': {
        p: {
          fontSize: ['1rem', '1.1rem', '1.25rem', '1.3rem']
        },
        marginBottom: '1.5rem'
      }
    },
    '.imageContainer': {
      width: ['100%', '', '', '35%'],
      height: 'auto',
      minHeight: 'unset',
      '.lazyload-wrapper': {
        flexDirection: ['', '', '', 'column']
      },
      '.imageColumn': {
        width: ['', '', '', '100%']
      }
    },

    width: ['95%', '', '90%'],
    margin: '0rem auto'
  }
}
